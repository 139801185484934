html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: #ecf0f5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiCollapse-container::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  border: 0px solid white !important;
  background-color: rgba(21, 39, 51, .7) !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

#root {
  height: 100%;
}

.MuiMenuItem-root {
  font-size: 0.8rem !important;
  line-height: 1.5;
  padding-top: 5px;
}

.MuiAutocomplete-listbox, .MuiMenu-list {
  padding: 0px !important;
}

.MuiAutocomplete-listbox li, .MuiMenu-list li {
padding: 6px 10px;
}

.MuiAutocomplete-listbox p, .MuiMenu-list p {
  font-size: 0.8rem;
}